import React from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { FieldPath } from 'react-hook-form/dist/types/path'
import { FieldValues } from 'react-hook-form/dist/types/fields'
import { Field, Input } from '@fluentui/react-components'

export function FormTextInput<
    FormVals extends FieldValues,
    Field extends FieldPath<FormVals>,
>({
    form,
    field,
    label,
}: {
    label: string
    form: UseFormReturn<FormVals>
    field: Field
}) {
    return (
        <Controller
            name={field}
            control={form.control}
            render={({ field: { value, onChange } }) => (
                <Field label={label}>
                    <Input
                        value={value}
                        onChange={onChange}
                        onKeyUp={(k) => {
                            // if (k.key === 'Enter') form.handleSubmit(() => {})
                        }}
                    />
                </Field>
            )}
        />
    )
}
