import React from 'react'
import { AdminPage } from '../AdminPage'
import { AddDocuments, SourceDocsList } from './DocumentsPanel'
import { DocumentsDetail } from './DocumentsDetail'
import { DrawerPanel, useDrawerPanelState } from '../../ui/DrawerPanel'
import { Button } from '@fluentui/react-components'
import { css } from '@emotion/react'
import { useCollectionStore } from '../../chat/SearchCollectionsStore'
import { observer } from 'mobx-react'

export const SourceDocsPage = observer(() => {

    const drawer = useDrawerPanelState()
    const col = useCollectionStore().selected

    return (
        <AdminPage title={'Source documents'}
                   titleLeftContent={
                       <Button
                           css={css`
                               margin-left: auto;
                           `}
                           size={'small'}
                           onClick={() => drawer.show()}
                           appearance={'primary'}>Add</Button>

                   }
        >
            <div css={css`
                display: flex;
                justify-content: space-between
            `}>
                {col && <SourceDocsList />}
                <div css={css`
                    margin-right: 2em;
                    //margin-left: 20em;
                    width: 70em;
                `}>

                    <DocumentsDetail />
                </div>
            </div>
            <DrawerPanel state={drawer}
                         width={'40em'}
                         title={'Add new documents'}>
                <AddDocuments onSuccess={drawer.close} />
            </DrawerPanel>
        </AdminPage>
    )
})
