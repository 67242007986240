import { useEffect, useState } from 'react'

export const DocumentContent = ({
    getFile,
    height,
    title,
    page,
}: {
    getFile: () => Promise<File>
    title: string
    height?: string
    page?: number
}) => {
    if (!page) page = 1

    const [url, setUrl] = useState<string | undefined>()

    useEffect(() => {
        getFile().then((f) => {
            setUrl(URL.createObjectURL(f))
        })
    }, [])

    return url ? (
        <embed
            title={title}
            src={url + `#page=${page}`}
            // src={url}
            width="100%"
            height={height || '500px'}
        />
    ) : null
}
