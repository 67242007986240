import { ComponentType } from 'react'
import { DrawerPanel, DrawerPanelState } from '../../ui/DrawerPanel'
import { ChatbotQAAdditionalInfo, LlmTokenUsage } from '@apis/my'
import { css } from '@emotion/react'


export const TokenUsage: ComponentType<{ value: ChatbotQAAdditionalInfo }> = ({ value: v }) => {

    const rows: [LlmTokenUsage, string][] = [[v.queryTokenUsage, 'Query'], [v.answerTokenUsage, 'Answer'], [v.tokenUsage, 'Total']]


    return <table>
        <thead>
        <tr>
            <th css={css` width: 5em; `}>Model</th>
            <th css={css` width: 8em; `}>Prompt token</th>
            <th css={css` width: 9em; `}>Completion token</th>
            <th css={css` width: 9em; `}>Tokens total</th>
        </tr>
        </thead>
        <tbody>
        {rows.map((([u, name], i) =>
                <tr key={i}>
                    <td>{name}</td>
                    <td>{u.prompt}</td>
                    <td>{u.completion}</td>
                    <td css={css`
                        //font-weight: bold;
                    `}>{u.total}</td>
                </tr>
        ))}
        </tbody>
    </table>
}


export const QAAdditionalInfo: ComponentType<{
    data: ChatbotQAAdditionalInfo | null
    question?: string,
    drawer: DrawerPanelState
}> = ({ data, drawer, question }) => {

    return <DrawerPanel state={drawer}
                        width={'80em'}
                        title={question ?? ''}>
        {data && <div css={css`
            padding-bottom: 2em;
        `}>
            <h3>LLM</h3>
            <div css={css`
                display: flex;

                > * {
                    margin-right: 2em;
                }
            `}>
                <div>
                    <h4>Models</h4>
                    <div>
                        <div css={css` font-weight: bold; `}>Query</div>
                        {data.queryLlmId}
                    </div>
                    <div>
                        <div css={css` font-weight: bold; `}> Answer</div>
                        {data.answerLlmId}
                    </div>
                </div>
                <div>
                    <h4>Token usage</h4>
                    <TokenUsage value={data} />
                </div>
            </div>
            <hr />
            <h3>Query prompt</h3>
            <span>{data.queryPrompt}</span>
            <h3>Query</h3>
            <span>{data.query}</span>
            <h3>Answer prompt</h3>
            <span>{data.answerPrompt?.map(((r, i) =>
                    <div key={i}>
                        <h4>{r.role}</h4>
                        <div css={css`
                            white-space: pre-wrap;
                        `}>
                            {r.content}
                        </div>
                    </div>
            ))}</span>
            {/*<span>{data.searchResults.map(r => <div key={r.name}>{r.content}</div>)}</span>*/}

        </div>}
    </DrawerPanel>

}
