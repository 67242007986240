import { makeObservable, observable, observe } from 'mobx'
import { SourceDocument } from '@apis/my'
import { MyApi } from '../api/api'
import { useAppContext } from '../ctx/MyContext'
import { SearchCollectionStore } from '../chat/SearchCollectionsStore'

// https://mobx.js.org/enabling-decorators.html
export class SearchConsoleStore {
    constructor(private api: MyApi, private collection: SearchCollectionStore) {
        // reaction(() => this.collectionId, this.fetchCollectionInfo)
        makeObservable(this)
        observe(collection, 'selected', (v) => {
            // Unselected document if there is a new (or none) SearchCollection selected
            if (this.document) {
                if (this.document.collection.id !== v.newValue?.id)
                    this.document = undefined
            }
        })
    }

    @observable accessor document: SourceDocument | undefined = undefined

    // @observable accessor collectionId: number

    // @flow
    // *fetch() {
    //     const response = yield fetch('/api/value')
    //     this.value = response.json()
    // }
}

export const useSearchConsoleStore = (): SearchConsoleStore => {
    return useAppContext().search.consoleStore
}
