import { OpState } from './useOpState'
import React from 'react'
import { ErrorMsg } from './ErrorMsg'
import { Spinner, SpinnerProps } from './Spinner'

export function OpStatus<D>({
                                op,
                                spinnerSize,
                                className,
                            }: {
    op: OpState<D>
    className?: string
    spinnerSize?: SpinnerProps['size']
}) {
    return (
        <div className={className}>
            <Spinner shown={op.pending} size={spinnerSize} />
            <ErrorMsg error={op.error} />
        </div>
    )
}
