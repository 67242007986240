import * as React from 'react'
import { ComponentType } from 'react'
import { Tab, TabList } from '@fluentui/react-components'
import { DefinedState } from './state'
import { css } from '@emotion/react'

export const Tabs: ComponentType<{
    selected: DefinedState<string>
    className?: string
    tabs: {
        label: string
        key: string
        disabled?: boolean
        render: () => React.ReactNode
    }[]
}> = ({ tabs, selected, className }) => {
    const selectedTab = tabs.find((t) => t.key === selected.val)!
    return (
        <div className={className}>
            <TabList
                selectedValue={selected.val}
                onTabSelect={(_, d) => selected.set(d.value as string)}
            >
                {tabs.map((t) => {
                    return (
                        <Tab key={t.key} value={t.key}
                             disabled={t.disabled}
                        >
                            {t.label}
                        </Tab>
                    )
                })}
            </TabList>
            <div css={css`
                overflow-y: scroll;
            `}>{selectedTab.render()}</div>
        </div>
    )
}
