import React, { ComponentType } from 'react'
import { useApi, useApiData } from '../api/useApi'
import { Button } from '@fluentui/react-components'
import { css } from '@emotion/react'


const LlmInfo: ComponentType<{ id: string }> = ({ id }) => {
    const api = useApi()

    return <div>
        <div>{id}</div>
        {/*<Button*/}
        {/*    css={css`*/}
        {/*        margin-top: 0.5em;*/}
        {/*    `}*/}
        {/*    size={'small'}*/}
        {/*    onClick={() => {*/}
        {/*        api.llm.askVersion({ id })*/}
        {/*            .then(r => {*/}
        {/*                console.log(r)*/}
        {/*            })*/}
        {/*    }}>Check version</Button>*/}
    </div>

}

export const Settings = () => {

    const api = useApi()

    const conf = useApiData(() => api.config.getConfig())

    return (
        <div>
            <h1>Settings</h1>
            <hr />
            {conf && <div>
                <h2>LLM</h2>
                <h3>Query model</h3>
                <LlmInfo id={conf.llm.queryModel} />
                <h3>Chat model</h3>
                <LlmInfo id={conf.llm.chatModel} />
                <h2>Embeddings</h2>
                <h3>Model</h3>
                <div>{conf.vector.embedders[0].displayName}, {conf.vector.embedders[0].vectorSize} dimensions</div>
                <h2>Search</h2>
                <h3>Azure AI Search</h3>
                <div>{conf.search.aiSearchEndpoint}</div>

            </div>}

        </div>
    )
}

