import { ComponentType, useState } from 'react'
import { CircleFilled, CircleRegular, StarFilled, StarRegular } from '@fluentui/react-icons'
import { css } from '@emotion/react'
import { range } from 'rambda'
import { useAppTheme } from '../../branding/useTheme'

export const Rating: ComponentType<{
    max: number,
    value?: number,
    onRatingSelected: (val: number) => void
}> = ({ max, value, onRatingSelected }) => {

    const [hoverIndex, setHoverIndex] = useState(-1)

    const t = useAppTheme()

    const starStyle = t.ratingStarIcon

    const EmptyIcon = starStyle ? StarRegular : CircleRegular
    const FilledIcon = starStyle ? StarFilled : CircleFilled

    const size = starStyle ? '1.3em' : '1.2em'

    return <div
        css={css`
        `}
        onMouseLeave={() => {
            setHoverIndex(-1)
        }}>
        {range(1, max + 1).map(r => {

                const selectedByValue = value && (r <= value)
                const selected = hoverIndex > -1 ? r <= hoverIndex : selectedByValue


                const Comp = selected ? FilledIcon : EmptyIcon

                return (
                    <Comp
                        key={r}
                        onClick={() => onRatingSelected(r)}
                        onMouseEnter={() => setHoverIndex(r)}
                        css={css`
                            border: none;
                            color: ${selected ? t.color : 'auto'};
                            width: ${size};
                            height: ${size};
                            cursor: pointer;

                            &:active {
                                // TODO maybe take from the theme?
                                color: black
                            }
                        `}
                    />
                )
            },
        )}
    </div>
}

