import { animated, useSpring } from '@react-spring/web'

import styles from './Answer.module.css'
import { AnswerIcon } from './AnswerIcon'

export const AnswerLoading = () => {
    const animatedStyles = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
    })

    return (
        <animated.div style={{ ...animatedStyles }}>
            <div className={styles.answerContainer}>
                <AnswerIcon />
                <div>
                    <p className={styles.answerText}>
                        Generating answer
                        <span className={styles.loadingdots} />
                    </p>
                </div>
            </div>
        </animated.div>
    )
}
