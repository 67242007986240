import { AskResponse, ChatbotSettings, ChatMessage as ApiChatMessage, MyApi } from '@apis/my'
import { SearchCollectionStore } from './SearchCollectionsStore'
import { computed, observable } from 'mobx'
import { useAppContext } from '../ctx/MyContext'

export type ChatMsg =
    | {
    role: 'user'
    content: string
}
    | {
    role: 'assistant'
    content: string
    res: AskResponse
}

export class ChatStore {
    constructor(
        private api: MyApi,
        private collections: SearchCollectionStore,
    ) {
        // makeObservable(this)
    }

    @computed get enabled() {
        return this.collections.selected
    }

    @observable accessor msgs: ChatMsg[] = []

    @observable accessor lastAnswer: AskResponse | undefined = undefined

    hasStarted = (): boolean => this.msgs.length > 0

    @computed get canEnterMsg(): boolean {
        // No conversation for now just and answer
        return this.msgs.length < 1
    }


    // @computed get lastMsg(): ChatMsg {
    //     return this.msgs[this.msgs.length - 1]
    // }

    @observable accessor settings: ChatbotSettings | undefined = undefined

    send = async (messages: ApiChatMessage[]) => {
        if (!this.collections.selected)
            throw new Error(`No collection selected`)
        return this.api.chat.msg({
            messages,
            collectionId: this.collections.selected!.id,
        })
    }

    submitMsg = async (msg: string): Promise<void> => {
        this.msgs.push({
            role: 'user',
            content: msg,
        })
        const res = await this.send(this.msgs.map(m => ({
            role: m.role,
            content: m.content,
        })))

        this.lastAnswer = res
        this.msgs.push({
            role: 'assistant',
            content: res.answer,
            res: res,
        })
    }

    askAgain = async (msgIndex: number): Promise<void> => {
        const msg = this.msgs[msgIndex]

        this.clearLastAnswer()

        if (msg.role !== 'user')
            throw new Error(`The ${msgIndex} msg is not a user message`)

        this.msgs = this.msgs.filter((_, i) => i < msgIndex)
        return this.submitMsg(msg.content)
    }

    ratingScale: number[] = [1, 2, 3, 4, 5]

    @computed get canRate(): boolean {
        return this.lastAnswer !== undefined
    }

    @observable accessor submittedRating = -1

    rateLastAnswer = async (rating: number): Promise<void> => {
        const prev = this.submittedRating

        this.submittedRating = rating
        // TODO errors in interceptors ?
        this.api.chat.rateAnswer({
            id: this.lastAnswer!.qaId,
            rating,
        }).catch(err => {
            this.submittedRating = prev
            throw err
        })

    }

    private clearLastAnswer = () => {
        this.lastAnswer = undefined
        this.submittedRating = -1
    }

    clearChat = () => {
        this.msgs = []
        this.clearLastAnswer()
    }
}

export const useChatStore = (): ChatStore => {
    return useAppContext().chat.store
}
