import { useAppContext } from '../../ctx/MyContext'
import * as React from 'react'
import { ComponentType, useEffect, useReducer, useState } from 'react'
import { ChatbotQA, ChatbotQAAdditionalInfo, MyApi } from '@apis/my'
import { css } from '@emotion/react'
import {
    ChatBubblesQuestionRegular,
    ChatSparkleRegular,
    ChevronLeftRegular,
    ChevronRightRegular,
} from '@fluentui/react-icons'
import {
    Button,
    createTableColumn,
    DataGrid,
    DataGridBody,
    DataGridCell,
    DataGridHeader,
    DataGridHeaderCell,
    DataGridRow,
    TableCellLayout,
    TableColumnDefinition,
} from '@fluentui/react-components'
import { useForm } from 'react-hook-form'
import { useDrawerPanelState } from '../../ui/DrawerPanel'
import { QAAdditionalInfo } from './QAAdditionalInfo'
import { AdminPage } from '../AdminPage'
import { InputWithButton } from '../ui/InputWithButton'


const changePageReducer = (num: number, action: 'inc' | 'decr') => {
    if (action === 'inc')
        return num + 1

    return num - 1
}

export type PageRes<T> = {
    items: T[]
    pageCount: number
    totalItemCount: number
    page: number
    first: boolean
    last: boolean
}


export const QaLogs = () => {

    const { api } = useAppContext()

    // const [page, setPage] = useState(0)

    const [page, changePage] = useReducer(changePageReducer, 0)
    const [ans, setAnswers] = useState<PageRes<ChatbotQA> | null>(null)

    const [sort, setSort] = useState<{ column: string, direction: 'asc' | 'desc' } | null>(null)
    const [search, setSearch] = useState<string>('')

    const form = useForm<{ search: string }>()

    const drawer = useDrawerPanelState()

    useEffect(() => {
        type PageReq = Parameters<MyApi['chat']['getAnswers']>[0]
        const req: PageReq = {
            number: page,
            size: 10,
            search,
        }

        if (sort) {
            req.sortBy = sort.column
            req.sortDir = sort.direction ?? 'asc'
        }

        api.chat.getAnswers(req)
            .then(setAnswers)
    }, [page, sort, search])


    const [selected, setSelected] = useState<ChatbotQA | null>(null)
    const [aInfo, setAInfo] = useState<ChatbotQAAdditionalInfo | null>(null)

    return <AdminPage title={'QA logs'}>
        <div css={css`
            display: flex;
            align-items: center;
        `}>
            <div css={css`
                display: flex;
                margin-right: 2em;

                > * {
                    margin-left: 0.5em;
                }
            `}>
                <Button
                    size={'small'}
                    icon={<ChevronLeftRegular />}
                    onClick={() => changePage('decr')}
                    disabled={page === 0}
                />
                <span>Page {page + 1}</span>
                <Button
                    size={'small'}
                    icon={<ChevronRightRegular />}
                    onClick={() => changePage('inc')}
                    disabled={ans?.last}
                />
            </div>
            <form onSubmit={
                form.handleSubmit(d => {
                        setSearch(d.search)
                    },
                )
            }>
                <InputWithButton
                    placeholder={'Search...'}
                    regInput={form.register('search')}
                    buttonText={'Search'}
                />
            </form>
        </div>

        {ans &&
            <Table items={ans.items}
                   onSortChange={p => {
                       setSort(p)
                   }}
                   onRowClick={i => {
                       const id = i.id
                       setSelected(i)
                       drawer.show()
                       api.chat.getAdditionalInfo(id)
                           .then(setAInfo)

                   }}
            />}
        <QAAdditionalInfo
            question={selected?.question}
            data={aInfo}
            drawer={drawer} />

    </AdminPage>
}


const backendCompare = (a: any, b: any) => 0

const dateTimeFormatter = new Intl.DateTimeFormat('default', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
})

const columns: TableColumnDefinition<ChatbotQA>[] = [
    createTableColumn({
        columnId: 'question',
        compare: backendCompare,
        renderHeaderCell: () => {
            return 'Question'
        },
        renderCell: (item) => {
            return (
                <TableCellLayout media={<ChatBubblesQuestionRegular />}>
                    {item.question}
                </TableCellLayout>
            )
        },
    }),
    createTableColumn({
        columnId: 'answer',
        compare: backendCompare,
        renderHeaderCell: () => {
            return 'Answer'
        },
        renderCell: (item) => {
            return (
                <TableCellLayout media={<ChatSparkleRegular />}
                                 css={css`
                                     align-items: flex-start;
                                     padding: 0.5em;
                                 `}
                >
                    <div css={css`
                    `}>
                        {item.answer}
                    </div>
                </TableCellLayout>
            )
        },
    }),
    createTableColumn({
        columnId: 'rating',
        compare: backendCompare,
        renderHeaderCell: () => {
            return 'Rating'
        },
        renderCell: (item) => {
            return item.rating || 'no rating'
        },
    }),
    createTableColumn({
        compare: backendCompare,
        columnId: 'createdAt',
        renderHeaderCell: () => {
            return 'Time'
        },
        renderCell: (item) => {
            const date = Date.parse(item.createdAt)

            return dateTimeFormatter.format(date)
        },
    }),
    createTableColumn({
        compare: backendCompare,
        columnId: 'user',
        renderHeaderCell: () => {
            return 'User'
        },
        renderCell: (item) => {
            return item.user
        },
    }),
    createTableColumn({
        compare: backendCompare,
        columnId: 'collectionName',
        renderHeaderCell: () => {
            return 'Collection'
        },
        renderCell: (item) => {
            return item.collectionName
        },
    }),
]

const Table: ComponentType<{
    items: ChatbotQA[],
    onSortChange: (opts: { column: string, direction: 'asc' | 'desc' }) => void
    onRowClick: (row: ChatbotQA) => void
}> = ({ items, onSortChange, onRowClick }) => {
    return (
        <DataGrid
            resizableColumns
            columnSizingOptions={
                {
                    question: {
                        minWidth: 400,
                    },
                    answer: {
                        minWidth: 700,
                    },
                    rating: {
                        minWidth: 100,
                    },
                    createdAt: {
                        minWidth: 200,
                    },
                    user: {
                        minWidth: 150,
                    },
                    collectionName: {
                        minWidth: 150,
                    },
                }}
            items={items}
            columns={columns}
            sortable={true}
            // defaultSortState={defaultSortState}
            onSortChange={(a, b) => {
                onSortChange({
                    // TODO
                    column: b.sortColumn as any,
                    direction: b.sortDirection === 'ascending' ? 'asc' : 'desc',
                })
            }}
        >
            <DataGridHeader>
                <DataGridRow>
                    {({ renderHeaderCell }) => (
                        <DataGridHeaderCell>{renderHeaderCell()}
                        </DataGridHeaderCell>
                    )
                    }
                </DataGridRow>
            </DataGridHeader>
            <DataGridBody<ChatbotQA> >
                {({ item, rowId }) => (
                    <DataGridRow<ChatbotQA> key={rowId}
                                            css={css`
                                                cursor: pointer;
                                            `}
                                            onClick={() => {
                                                onRowClick(item)
                                            }}
                    >
                        {({ renderCell }) => (
                            <DataGridCell>{renderCell(item)}</DataGridCell>
                        )}
                    </DataGridRow>
                )}
            </DataGridBody>
        </DataGrid>
    )
}
