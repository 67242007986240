import { OpState } from './useOpState'
import React from 'react'
import { OpStatus } from './OpStatus'
import { Button } from '@fluentui/react-components'

export function FormSubmit<D>({
    op,
}: {
    op: OpState<D>
}) {
    return (
        <div>
            {!op.pending && <Button type={'submit'}>Save</Button>}
            <OpStatus op={op}/>
        </div>
    )
}
