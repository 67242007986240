import { css } from '@emotion/react'
import { ComponentType, ReactNode } from 'react'

export const AdminPage: ComponentType<{
    title: string,
    children: ReactNode,
    titleLeftContent?: ReactNode
}> = ({
          title,
          children,
          titleLeftContent,
      }) => {

    return <div>
        <div
            css={css`
                display: flex;
                align-items: center;
                width: 50em;
            `}>

            <h1>{title}</h1>
            {titleLeftContent}
        </div>
        {children}
    </div>

}
