import { Example } from './Example'

import styles from './Example.module.css'
import { useAppContext } from '../../ctx/MyContext'

interface Props {
    onExampleClicked: (value: string) => void
}

export const ExampleList = ({ onExampleClicked }: Props) => {
    const { chat } = useAppContext();

    return (
        <ul className={styles.examplesNavList}>
            {chat.store.settings?.questions.map((q, i) => (
                <li key={i}>
                    <Example text={q} value={q} onClick={onExampleClicked} />
                </li>
            ))}
        </ul>
    );
};
