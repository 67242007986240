import React from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { FieldPath } from 'react-hook-form/dist/types/path'
import { FieldValues } from 'react-hook-form/dist/types/fields'
import { Field } from '@fluentui/react-components'
import { Option, RadioGroup } from '../RadioGroup'


export function FormRadioGroup<
    FormVals extends FieldValues,
    Field extends FieldPath<FormVals>,
>({
      form,
      field,
      label,
      options,
  }: {
    label: string
    form: UseFormReturn<FormVals>
    field: Field
    options: Option[],
}) {
    return (
        <Controller
            name={field}
            control={form.control}
            render={({ field: { value, onChange } }) => (
                <Field label={label}>
                    <RadioGroup value={value}
                                options={options}
                                onChange={onChange} />
                </Field>
            )}
        />
    )
}

export function FormEnumRadioGroup<
    E extends string,
    FormVals extends FieldValues,
    Field extends FieldPath<FormVals>,
>({
      form,
      field,
      label,
      enumObj,
  }: {
    label: string
    form: UseFormReturn<FormVals>
    field: Field
    enumObj: Record<string, E>,
}) {
    return (
        <Controller
            name={field}
            control={form.control}
            render={({ field: { value, onChange, disabled } }) => (
                <Field label={label}>
                    <RadioGroup value={value}
                                disabled={disabled}
                                options={Object.values(enumObj).map(v => ({
                                    label: v,
                                    value: v
                                }))}
                                onChange={onChange} />
                </Field>
            )}
        />
    )
}

