import { Drawer, DrawerBody, DrawerHeader, DrawerHeaderTitle } from '@fluentui/react-drawer'
import React, { ComponentType, useState } from 'react'
import { Button } from '@fluentui/react-components'
import { Dismiss24Regular } from '@fluentui/react-icons'
import { css } from '@emotion/react'
import { observer } from 'mobx-react'


export type DrawerPanelState = {
    shown: boolean,
    setShown: (b: boolean) => void,
    close: () => void
    show: () => void
}

export const useDrawerPanelState = (): DrawerPanelState => {
    const [shown, setShown] = useState(false)

    return {
        shown, setShown, close: () => setShown(false),
        show: () => setShown(true),
    }
}

export const DrawerPanel: ComponentType<{
    state: DrawerPanelState,
    title: string
    children: React.ReactNode
    width?: string
}> = observer(({ title, state, children, width = '50em' }) => {

    return (
        <Drawer
            // type={shown}
            separator
            css={css`
                width: ${width};
            `}
            position={'end'}
            open={state.shown}
            onOpenChange={(_, { open }) => {
                if (!open) state.setShown(false)
            }}
        >
            <DrawerHeader>
                <DrawerHeaderTitle
                    action={
                        <Button
                            appearance="subtle"
                            aria-label="Close"
                            icon={<Dismiss24Regular />}
                            onClick={() => state.setShown(false)}
                        />
                    }
                >
                    {title}
                </DrawerHeaderTitle>
            </DrawerHeader>
            <DrawerBody>
                {children}
            </DrawerBody>
        </Drawer>
    )
})
