import data from './texts.yml'

export type Texts = {
    askAnything: string
    typeANewQuestion: string
    eg: string
}

export const useText = (): Texts => {
    // For now hardcoded German
    return data['de'] as Texts
}
