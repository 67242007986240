import { useState } from 'react'

export type OpState<D> = {
    error?: Error
    pending: boolean
    success: boolean
    notSuccess: boolean
    data?: D
}

const defaultState = {
    error: undefined,
    pending: false,
    success: false,
    notSuccess: true,
}

export const useOpState = <D = any>(): OpState<D> & {
    handle: (p: Promise<D>) => Promise<D>
    reset: () => void
} => {
    const [s, setS] = useState<OpState<D>>(defaultState)

    const handle = async (p: Promise<any>) => {
        const state = {
            ...s,
            error: undefined,
            pending: true,
            success: false,
            notSuccess: false,
        }
        setS(state)
        return p
            .then((data) => {
                setS({
                    ...state,
                    success: true,
                    notSuccess: false,
                    data,
                    pending: false,
                })
                return data
            })
            .catch((error) => {
                setS({
                    ...state,
                    error,
                    success: false,
                    notSuccess: true,
                    pending: false,
                })
                throw error
            })
    }

    return {
        ...s,
        handle,
        reset: () => {
            setS(defaultState)
        },
    }
}

export type OpStateHookRes<D> = ReturnType<typeof useOpState<D>>
