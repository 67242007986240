import { ShowToast } from '../ui/Toast'
import { NavigateFunction } from 'react-router-dom'

export class UiControls {
    showToast: ShowToast = null as any
    navigate: NavigateFunction = null as any

    register = {
        showToast: (st: ShowToast) => {
            this.showToast = st
        },
        navigate: (nav: NavigateFunction) => {
            this.navigate = nav
        },
    }
}
