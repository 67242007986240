import * as React from 'react'
import { ComponentType } from 'react'
import { Button, Input } from '@fluentui/react-components'
import { css } from '@emotion/react'
import { UseFormRegisterReturn } from 'react-hook-form/dist/types/form'

export const InputWithButton: ComponentType<{
    buttonText?: string,
    placeholder?: string,
    inputWidth?: string
    regInput: UseFormRegisterReturn<string>
}> = ({ buttonText, regInput, placeholder, inputWidth }) => {
    return <div>
        <Input
            placeholder={placeholder}
            {...regInput}
            css={css`
                width: ${inputWidth ?? '15em'}
            `}
        />
        <Button
            type={'submit'}
            css={css`
                //border-left: none;
                margin-left: 0.5em;
            `}
            // size={'small'}
        >{buttonText ?? 'Submit'}</Button>

    </div>

}
