import React, { ComponentType } from 'react'
import { observer } from 'mobx-react'
import { useApi } from '../api/useApi'
import { useCollectionStore } from '../chat/SearchCollectionsStore'
import { RadioGroup } from '../ui/RadioGroup'
import { Link } from 'react-router-dom'
import { css } from '@emotion/react'

// TODO
const collectionScreenPath = '/admin/collection'

export const CollectionSelector: ComponentType<{
    horizontal?: boolean
}> = observer(({ horizontal } = { horizontal: false }) => {
    const api = useApi()
    const store = useCollectionStore()
    const { items, selected, select } = store

    return (
        <div css={css`
            //width: 100%;
        `}>

            {items?.length === 0 && <span>No collections yet <Link to={collectionScreenPath}>
                Create one
            </Link></span>}
            <RadioGroup
                value={selected?.id.toString()}
                horizontal={horizontal}
                onChange={(id) => {
                    select(parseInt(id))
                }}
                options={(items ?? []).map((c) => ({
                    value: c.id.toString(),
                    label: c.name,
                }))}
            />
        </div>
    )
})
