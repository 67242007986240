import { useState } from 'react'
import { Field, tokens } from '@fluentui/react-components'
import { Send28Filled } from '@fluentui/react-icons'

import styles from './QuestionInput.module.css'
import { useAppTheme } from '../../branding/useTheme'
import { css } from '@emotion/react'
import { useChatStore } from '../../chat/ChatStore'
import { observer } from 'mobx-react'

interface Props {
    onSend: (question: string) => void
    disabled: boolean
    placeholder?: string
    clearOnSend?: boolean
}

export const QuestionInput = observer(({
    onSend,
    disabled,
    placeholder,
    clearOnSend,
}: Props) => {
    const [question, setQuestion] = useState<string>('')

    const theme = useAppTheme()

    const chat = useChatStore()

    const sendQuestionDisabled = disabled || !question.trim() || !chat.enabled

    const sendQuestion = () => {
        if (sendQuestionDisabled)
            return

        if (disabled || !question.trim()) {
            return
        }

        onSend(question)

        if (clearOnSend) {
            setQuestion('')
        }
    }

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === 'Enter' && !ev.shiftKey) {
            ev.preventDefault()
            sendQuestion()
        }
    }

    const onQuestionChange = (
        ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        // @ts-ignore
        const newValue = ev.target.value
        if (!newValue) {
            setQuestion('')
        } else if (newValue.length <= 1000) {
            setQuestion(newValue)
        }
    }


    return (
        <div className={styles.questionInputContainer}>
            <Field className={styles.questionInputTextArea}>
                <textarea
                    onChange={onQuestionChange}
                    placeholder={placeholder}
                    css={css`
                        border: none;
                        outline: none;
                        font-family: ${tokens.fontFamilyBase};
                        font-size: 1em;
                    `}
                    value={question}
                    onKeyDown={onEnterPress}
                />
            </Field>
            <div className={styles.questionInputButtonsContainer}>
                <div
                    className={`${styles.questionInputSendButton} ${
                        sendQuestionDisabled
                            ? styles.questionInputSendButtonDisabled
                            : ''
                    }`}
                    aria-label="Ask question button"
                    onClick={sendQuestion}
                >
                    <Send28Filled primaryFill={theme.color} />
                </div>
            </div>
        </div>
    )
})
