import { Sparkle28Filled } from '@fluentui/react-icons'
import { useAppTheme } from '../../branding/useTheme'

export const AnswerIcon = () => {
    const theme = useAppTheme()
    return (
        <Sparkle28Filled
            primaryFill={theme.color}
            aria-hidden="true"
            aria-label="Answer logo"
        />
    )
}
