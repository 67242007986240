import { ComponentType } from 'react'
import { Spinner as FluentUiSpinner } from '@fluentui/react-components'
import { SpinnerProps as LibSpinnerProps } from '@fluentui/react-spinner'

export type SpinnerProps =  {
    children?: string;
    shown: boolean,
    size?: LibSpinnerProps['size']
}

export const Spinner: ComponentType<SpinnerProps> = ({
          size,
          children,
          shown,
      }) =>
    shown ? (
        <FluentUiSpinner labelPosition="below" label={children} size={size} />
    ) : null
