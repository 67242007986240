import { MyApi } from './api'
import { useAppContext } from '../ctx/MyContext'
import { useQuery } from 'react-query'
import { UseQueryResult } from 'react-query/types/react/types'
import { useEffect, useState } from 'react'

export const useApi = (): MyApi => useAppContext().api

let id = 1

export const useApiData = <D>(get: (api: MyApi) => Promise<D>): D | null => {
    const api = useApi()

    const [data, setData] = useState<D | null>(null)

    useEffect(() => {
        get(api).then(setData)
    }, [])

    return data
    // return useQuery(`query-${id++}`, () => get(api))
}
