import React, {ComponentType, useEffect} from 'react'
import {MyContext, MyContextProvider, useAppContext,} from './ctx/MyContext'
import {QueryClient, QueryClientProvider} from 'react-query'
import {ToasterProvider, useToast} from './ui/Toast'
import Routing from './routing'
import {StylingProvider} from "./ui/StylingProvider";

const queryClient = new QueryClient()

const RegisterUIControls = () => {
    const ctx = useAppContext()
    const t = useToast()

    useEffect(() => {
        // Navigate will be registered by the router itself
        ctx.ui.register.showToast(t.showToast)
    }, [])

    return null
}

export const App: ComponentType<{ ctx: MyContext }> = ({ctx}) => {
    // TODO load only after we know we are authenticated
    // useEffect(() => {
    //
    //     ctx.app.store.loadSettings().then(r => {
    //         const fav = document.getElementById(
    //             'favicon',
    //         ) as HTMLLinkElement
    //         fav.href = r.favicon.href
    //         fav.type = r.favicon.type
    //
    //         console.log('Running version ' + r.appVersion)
    //     })
    // }, [])

    return (
        <QueryClientProvider client={queryClient}>
            <MyContextProvider value={{...ctx}}>
                <StylingProvider>
                    <ToasterProvider>
                        <>
                            <RegisterUIControls/>
                            <Routing/>
                        </>
                    </ToasterProvider>
                </StylingProvider>
            </MyContextProvider>
        </QueryClientProvider>
    )
}
