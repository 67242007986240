import { BrandVariants, createLightTheme, Theme } from '@fluentui/react-components'
import { hex_to_LCH, hexColorsFromPalette, Palette } from './colors/index'

// Taken from https://github.com/microsoft/fluentui/blob/master/packages/react-components/theme-designer/src/Context/ThemeDesignerContext.tsx#L74


export function getBrandTokensFromPalette(keyColor: string, options: any = {}) {
    const { darkCp = 2 / 3, lightCp = 1 / 3, hueTorsion = 0 } = options
    const brandPalette: Palette = {
        keyColor: hex_to_LCH(keyColor),
        darkCp,
        lightCp,
        hueTorsion,
    }
    const hexColors = hexColorsFromPalette(keyColor, brandPalette, 16, 1)
    return hexColors.reduce((acc: Record<string, string>, hexColor, h) => {
        acc[`${(h + 1) * 10}`] = hexColor
        return acc
    }, {}) as BrandVariants
}

export type CustomAttributes = {
    keyColor: string;
    hueTorsion: number;
    vibrancy: number;
};

const createCustomTheme = ({ hueTorsion, keyColor, vibrancy }: CustomAttributes): BrandVariants => {
    return getBrandTokensFromPalette(keyColor, {
        hueTorsion,
        darkCp: vibrancy,
        lightCp: vibrancy,
    })
}

export const genTheme = (hexColour: string): Theme => {

    const t = createLightTheme(createCustomTheme({
        hueTorsion: 0,
        keyColor: hexColour,
        vibrancy: 0,
    }))

    return t
}
