import {css, Global, ThemeProvider} from "@emotion/react";
import React, {ComponentType} from "react";
import {hexToCssRgbVarVal} from "../util";
import {useAppContext} from "../ctx/MyContext";
import {observer} from "mobx-react";
import {FluentProvider, teamsLightTheme} from "@fluentui/react-components";
import { genTheme } from './theme/fluentUiTheme'


// const fluentIdTheme = teamsLightTheme



export const StylingProvider: ComponentType<{ children: React.ReactNode }> = observer(({children}) => {

    const ctx = useAppContext()
    const theme = ctx.app.store.settings.branding.theme
    const mainColor = theme.color

    let mainRgb = mainColor
    if (mainRgb.startsWith('#')) mainRgb = hexToCssRgbVarVal(mainRgb)

    const selectionColor = theme.outlineSelectionColor || theme.color
    let selectionRgb = selectionColor
    if (selectionRgb.startsWith('#'))
        selectionRgb = hexToCssRgbVarVal(selectionRgb)

    const fluentUiThem = genTheme(theme.color)

    return <FluentProvider
        css={
            css`
                // Causes toasts to block the UI TODO is this bcs of 2 fluent ui ver
                //height: 100%;
                background: none;
            `
        }
        theme={{
            ...fluentUiThem,

        }}
    >
        <ThemeProvider
            theme={{
                color: mainColor,
            }}
        >
            <Global
                styles={css`
                    // TODO - this works but not necessarily looks nice
                    //@font-face {
                    //    font-family: foo;
                    //    src: url('https://storage.googleapis.com/assets.chatbot.ai.garwan.io/anne-breitner/fonts/quasimoda/Quasimoda%20Black.otf');
                    //}
                    //body * {
                    //    font-family: foo, sans-serif !important;
                    //}

                    :root {
                        --main-color: ${mainColor};
                        --main-color-rgb: ${mainRgb};
                        --selection-color: ${theme.outlineSelectionColor ||
                        theme.color};
                        --selection-color-rgb: ${selectionRgb};
                        --border-radius: ${theme.borderRadius};
                        //--box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
                        --box-shadow: ${theme.boxShadow};
                        // TODO cannot use for now, this makes header shift to left when Drawer opens
                        //overflow-y: scroll;
                    }
                `}
            />
            <div css={css`
                // Putting this in FluentProvider causes toasts to block the UI
                min-height: 100vh;
                display: flex;
                flex-direction: column;
                //height: 100vh;
            `}>
                {children}
            </div>
        </ThemeProvider>
    </FluentProvider>
})
