import React from 'react'
import { QueryPanel } from './QueryPanel'
import { AdminPage } from './AdminPage'


export const SearchConsole = () => {
    return (
        <AdminPage title={'Search console'}>
            <QueryPanel/>
        </AdminPage>
    )
}
