import React, { ComponentType, useEffect } from 'react'
import { Button, Checkbox, Field, Input, Textarea } from '@fluentui/react-components'
import { Add16Regular, Delete16Regular } from '@fluentui/react-icons'
import { css } from '@emotion/react'
import { observer } from 'mobx-react'
import { useChatStore } from './ChatStore'
import { Controller, useForm } from 'react-hook-form'
import { ChatbotSettings } from '@apis/my'
import { useApi } from '../api/useApi'
import { FormSubmit } from '../ui/FormSubmit'
import { useOpState } from '../ui/useOpState'
import { DrawerPanel, DrawerPanelState } from '../ui/DrawerPanel'


export const ChatbotSettingsComp: ComponentType<{
    state: DrawerPanelState
}> = observer(({ state }) => {

    const chat = useChatStore()
    const api = useApi()

    // TODO what if this changes
    const form = useForm<ChatbotSettings>()

    useEffect(() => {
            form.reset(chat.settings)
        },
        // Reset when settings loaded - TODO, quick hack, implement properly
        [chat.settings?.name])


    const save = useOpState()

    return <DrawerPanel state={state} title={'Chatbot settings'}>
        <form
            css={css`
                > * {
                    margin-bottom: 2em;
                }
            `}
            onSubmit={form.handleSubmit(async data => {
                data = {
                    ...data,
                    answerPrompt: data.answerPrompt.trim(),
                    generateQueryPrompt: data.generateQueryPrompt.trim(),
                }
                await save.handle(
                    api.chat.saveSettings(data),
                )
                chat.settings = data
                state.close()
            })}>
            <Field label="Chat description">
                <Input
                    defaultValue={form.getValues('chatDescription')}
                    {...form.register('chatDescription', {
                        required: true,
                    })}
                />
            </Field>
            <Field label="Query generation prompt">
                <Textarea
                    resize={'vertical'}
                    {...form.register('generateQueryPrompt', {
                        required: true,
                    })}
                    rows={4}
                />
            </Field>
            <Field label="Answer prompt">
                <Textarea
                    resize={'vertical'}
                    {...form.register('answerPrompt', {
                        required: true,
                    })}
                    rows={5}
                />
            </Field>
            <Field label="Document search limit">
                <Input
                    type={'number'}
                    defaultValue={form.getValues('searchDocLimit') + ''}
                    {...form.register('searchDocLimit', {
                        valueAsNumber: true,
                        required: true,
                    })}
                />
            </Field>
            <Controller
                name="questions"
                control={form.control}
                render={({ field }) => {
                    const vals: string[] = field.value
                    return <div css={css`
                        display: flex;
                        flex-direction: column;

                        > * {
                            margin-bottom: 0.5em;
                        }
                    `}>
                        {vals.map((item, index) => (
                            <div key={index}>
                                <Input
                                    value={item}
                                    css={css`
                                        width: 25em;
                                    `}
                                    onChange={e => {
                                        const newArray = [...field.value]
                                        newArray[index] = e.target.value
                                        field.onChange(newArray)
                                    }}
                                />
                                <Button
                                    icon={<Delete16Regular />}
                                    css={css`
                                        margin-left: 1em;
                                    `}
                                    onClick={() => {
                                        field.onChange(vals.filter((_, i) => i !== index))
                                    }}></Button>
                            </div>
                        ))}
                        <Button
                            type="button"
                            icon={<Add16Regular />}
                            css={css`
                                width: 5em;
                            `}
                            onClick={() => {
                                const newArray = [...field.value, ''] // Add an empty string to array
                                field.onChange(newArray)
                            }}
                        >
                        </Button>
                    </div>
                }}
            />
            <hr />
            <h4>Compensations</h4>
            <Field label="Query unquoting" orientation={'horizontal'}>
                <Controller
                    name="compensationQueryUnquoting"
                    control={form.control}
                    render={({ field }) =>
                        <Checkbox
                            checked={field.value}
                            onChange={e => {
                                const val = e.target.checked
                                field.onChange(val)
                            }} />
                    } />
            </Field>
            <FormSubmit op={save} />
        </form>
    </DrawerPanel>
})
