import * as React from 'react'
import {ComponentType, ReactNode, useContext, useEffect} from 'react'
import {
    Toast,
    Toaster,
    ToastIntent,
    ToastTitle,
    ToastTrigger,
    useId,
    useToastController,
    Link
} from '@fluentui/react-components'

export const globalToasterId = 'toaster'

const MyToastContext = React.createContext<{
    showToast: ShowToast
}>(null as any)

const MyToastContextProvider = MyToastContext.Provider


export type ShowToastOpts = {
    error?: boolean
}

export type ShowToast = (msg: string, opt?: ShowToastOpts) => void

export const useToast = (): { showToast: ShowToast } => {
    const ctx = useContext(MyToastContext)

    return {
        showToast: ctx.showToast,
    }
}

// TODO the UI is unclickable when toast is present !
export const ToasterProvider: ComponentType<{
    children: ReactNode
    register?: (st: ShowToast) => void
}> = ({children, register}) => {
    const toasterId = useId(globalToasterId)
    const {dispatchToast} = useToastController(toasterId)

    const showToast: ShowToast = (msg: string, opts: ShowToastOpts = {}) => {

        let intent: ToastIntent = 'success'

        if (opts.error)
            intent = 'error'

        dispatchToast(
            <Toast appearance="inverted"

            >
                <ToastTitle
                    action={
                        <ToastTrigger>
                            <Link>Dismiss</Link>
                        </ToastTrigger>
                    }
                >{msg}</ToastTitle>
                {/*<ToastBody subtitle="Subtitle">*/}
                {/*    This is a toast body*/}
                {/*</ToastBody>*/}
                {/*<ToastFooter>*/}
                {/*    <Link>Action</Link>*/}
                {/*    <Link>Action</Link>*/}
                {/*</ToastFooter>*/}
            </Toast>,
            {intent, position: 'top', timeout: 2000},
        )
    }

    useEffect(() => {
        if (register) register(showToast)
    }, [])

    return (
        <>
            <Toaster toasterId={toasterId}/>
            <MyToastContextProvider
                value={{
                    showToast,
                }}
            >
                {children}
            </MyToastContextProvider>
        </>
    )
}
