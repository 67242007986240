import { ComponentType } from 'react'
import { css } from '@emotion/react'

export const TextWithWhiteSpace: ComponentType<{ children: string }> = ({ children }) =>
    <p css={css`
        white-space: pre-wrap;
    `}>
        {children}
    </p>

