import { ComponentType } from 'react'
import { css } from '@emotion/react'

export const InfoMsg: ComponentType<{
    children: string
    shown: boolean
}> = ({ children, shown }) =>
    shown ? (
        <span
            css={css`
                color: #4695eb;
            `}
        >
            {children}
        </span>
    ) : null
