import { ComponentType, useEffect, useState } from 'react'
import { useSearchConsoleStore } from '../SearchConsoleStore'
import { observer } from 'mobx-react'
import { useApi } from '../../api/useApi'
import prettyBytes from 'pretty-bytes'
import { SemanticTextEntity } from '@apis/my'
import { css } from '@emotion/react'

const contentShowingLImit = 1_000_000 * 5

export const DocumentsDetail: ComponentType<{}> = observer(() => {
    const doc = useSearchConsoleStore().document
    const api = useApi()

    const [file, setFile] = useState<{ title: string; url: string }>()

    const [entities, setEntities] = useState<SemanticTextEntity[]>([])

    useEffect(() => {
        if (!doc) return

        if (doc.sizeInBytes > contentShowingLImit) return
        api.search.getDocumentContent(doc.id).then((f) => {
            setFile({
                url: URL.createObjectURL(f),
                title: f.name,
            })
        })

        api.search.getDocumentTextEntities(doc.id).then(setEntities)
    }, [doc?.id])

    let page = 1
    if (!doc) return null

    const isTooBig = doc.sizeInBytes > contentShowingLImit

    return (
        <div>
                <h3>{doc.name} [{prettyBytes(doc.sizeInBytes)}]</h3>
            {!isTooBig && (
                <>
                    {file && (
                        <iframe
                            title={file?.title}
                            src={file.url + `#page=${page}`}
                            width="100%"
                            height={'500px'}
                        />
                    )}
                </>
            )}
            <h3>Text entities</h3>
            <div
                css={css`
                    max-height: 200em;
                    overflow: scroll;
                `}
            >
                {entities.map((e) => (
                    <div key={e.id}>
                        <h4>{e.name}</h4>
                        <pre>{e.content}</pre>
                    </div>
                ))}
            </div>
            {isTooBig && (
                <div>
                    File too large to show the content.
                    {/*<button onClick={() => {}}>Show</button>*/}
                </div>
            )}
        </div>
    )
})
