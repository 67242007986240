import React, { ComponentType, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useApi } from '../api/useApi'
import { useCollectionStore } from '../chat/SearchCollectionsStore'
import { useForm } from 'react-hook-form'
import { useOpState } from '../ui/useOpState'
import { css } from '@emotion/react'
import { FormTextInput } from '../ui/form/FormTextInput'
import { Button } from '@fluentui/react-components'
import { OpStatus } from '../ui/OpStatus'
import { Collection, PdfExtractorType, SearchBackendType, SearchCollectionInfo } from '@apis/my'
import { FormEnumRadioGroup } from '../ui/form/FormRadioGroup'
import { DrawerPanel, useDrawerPanelState } from '../ui/DrawerPanel'
import { AdminPage } from './AdminPage'


const KV: ComponentType<{ k: string, v: string }> = ({ k, v }) => {

    return <div css={css`
        display: flex;
        margin-bottom: 0.5em;
    `}>
        <div css={css`
            font-weight: bold;
            width: 15em;
        `}>{k}:
        </div>
        <div>{v}</div>
    </div>
}


export const CollectionAdmin = observer(() => {
    const store = useCollectionStore()

    const { selected, items } = store

    const data = store.collectionInfo
    const drawer = useDrawerPanelState()

    return <AdminPage title={'Collection'}
                      titleLeftContent={
                          <Button
                              css={css`
                                  margin-left: auto;
                              `}
                              size={'small'}
                              onClick={() => drawer.show()}
                              appearance={'primary'}>Create new</Button>

                      }
    >
        {selected && <div>
            <div
                css={css`
                    display: flex;
                    align-items: center;
                    width: 40em;
                `}
            >
                <h2>{store.selected?.name}</h2>
            </div>
            <CollectionInfo value={selected} info={data} />
        </div>}
        {items && items.length === 0 &&
            <div>No collection yet</div>
        }
        <DrawerPanel state={drawer}
                     width={'60em'}
                     title={'Create a new Collection'}>
            <CreateNew onSuccess={async id => {

                drawer.close()
                store.select(id)

            }} />
        </DrawerPanel>
    </AdminPage>
})

const CollectionInfo: ComponentType<{ value: Collection, info?: SearchCollectionInfo }> = ({ value, info }) => {

    const store = useCollectionStore()
    const op = useOpState()

    const api = useApi()

    const form = useForm<{
        searchBackend: SearchBackendType
        pdfExtractor: PdfExtractorType
    }>({
        disabled: true,
        values: {
            searchBackend: value.searchBackend,
            pdfExtractor: value.pdfExtractor,
        },
    })

    useEffect(() => {
        // TODO find better way
        form.reset({
            searchBackend: value.searchBackend,
            pdfExtractor: value.pdfExtractor,
        })
    }, [value.id])

    return (
        <div>
            <div>
                {/*<h3>Settings</h3>*/}
                {/*<div>Search: {value.searchBackend} </div>*/}
                <KV k={'Search'} v={value.searchBackend} />
                <KV k={'Content extractor'} v={value.pdfExtractor} />
                <KV k={'Semantic entities count'} v={info?.semanticEntitiesCount + ''} />
                <KV k={'Documents count'} v={info?.documentsCount + ''} />
                {/*<h3>Stats</h3>*/}
                {/*<div>Semantic entities count: {info?.semanticEntitiesCount}</div>*/}
                {/*<div>Documents count: {info?.documentsCount}</div>*/}
            </div>

            <div css={css`
                margin-top: 2em;
            `}>
                <Button
                    appearance={'primary'}
                    size={'small'}
                    onClick={() => {
                        let msg = 'Are you sure?'
                        if (info?.semanticEntitiesCount)
                            msg += `\n ${info?.semanticEntitiesCount} uploaded entries will be removed.`

                        const confirm = window.confirm(msg)
                        if (confirm) {
                            op.handle(
                                store.delete(value.id),
                            )
                        }
                    }}
                >
                    Delete
                </Button>
            </div>
            <OpStatus op={op} />
        </div>
    )
}

const CreateNew: ComponentType<{ onSuccess: (id: number) => void }> = observer(({ onSuccess }) => {
    const api = useApi()
    const store = useCollectionStore()

    const form = useForm<{
        name: string,
        searchBackend: SearchBackendType
        pdfExtractor: PdfExtractorType
    }>({
        values: {
            name: '',
            searchBackend: SearchBackendType.AZURE_AI_SEARCH,
            pdfExtractor: PdfExtractorType.JAVA_HELPER_SERVICE,
        },
    })

    const { handleSubmit } = form

    const op = useOpState<number>()

    return (
        <div>
            <form
                css={css`
                    > * {
                        margin-top: 1em;
                    }
                `}
                onSubmit={handleSubmit(async (d) => {
                    const id = await op.handle(
                        api.search.createCollection({
                            name: d.name,
                            pdfExtractor: d.pdfExtractor,
                            searchBackend: d.searchBackend,
                        }),
                    )

                    form.reset()
                    await store.fetch()
                    onSuccess(id)
                })}
            >
                <FormTextInput
                    label={'Collection name'}
                    form={form}
                    field={'name'}
                />
                <FormEnumRadioGroup
                    enumObj={SearchBackendType}
                    form={form}
                    field={'searchBackend'}
                    label={'Search backend'}
                />
                <FormEnumRadioGroup
                    enumObj={PdfExtractorType}
                    form={form}
                    field={'pdfExtractor'}
                    label={'PDF extract'}
                />
                <Button type={'submit'} appearance={'primary'}>
                    Create
                </Button>
                <OpStatus op={op} />
            </form>
        </div>
    )
})
