import { ComponentType, useMemo } from 'react'
import DOMPurify from 'dompurify'

import styles from './Answer.module.css'

import { useAppContext } from '../../ctx/MyContext'
import { parseAnswerToHtml } from './AnswerParser'
import { AnswerIcon } from './AnswerIcon'
import { AskResponse } from '@apis/my'
import { css } from '@emotion/react'
import { ArrowRepeatAllRegular, ClipboardBulletListLtrRegular, LightbulbRegular } from '@fluentui/react-icons'
import { IReactComponent } from 'mobx-react/dist/types/IReactComponent'

interface Props {
    answer: AskResponse
    isSelected?: boolean
    onCitationClicked: (filePath: string) => void
    onThoughtProcessClicked: () => void
    onSupportingContentClicked: () => void
    onAnswerAgain?: () => void
}


const Icon: ComponentType<{
    icon: IReactComponent,
    title: string,
    disabled?: boolean,
    onClick: () => void
}> = ({
          icon: Comp,
          title,
          onClick,
          disabled,
      }) => {
    return <button
        css={css`
            background: none;
            border: none;
            cursor: pointer;
            font-size: 1.5em;

            &:active {
                //font-size: 1.47em;
            }
        `}
        onClick={onClick}
        title={title}
        disabled={disabled}
    >
        <Comp />
    </button>
}


export const Answer = ({
                           answer,
                           isSelected,
                           onCitationClicked,
                           onThoughtProcessClicked,
                           onSupportingContentClicked,
                           onAnswerAgain,
                       }: Props) => {

    const ctx = useAppContext()

    const parsedAnswer = useMemo(
        () => parseAnswerToHtml(answer.answer, onCitationClicked),
        [answer],
    )

    const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml)

    return (
        <div
            className={`${styles.answerContainer} ${
                isSelected && styles.selected
            }`}
            // verticalAlign="space-between"
        >
            <div css={css`
                //background: red;
            `}>
                <div css={css`
                    display: flex;
                `}>
                    <AnswerIcon />
                    <div css={css`
                        margin-left: auto;
                    `}>
                        {onAnswerAgain &&
                            <Icon
                                title="Answer agai"
                                disabled={!answer.dataPoints.length}
                                onClick={onAnswerAgain}
                                icon={ArrowRepeatAllRegular} />
                        }

                        <Icon
                            title="Show supporting content"
                            disabled={!answer.dataPoints.length}
                            onClick={onThoughtProcessClicked}
                            icon={LightbulbRegular} />
                        <Icon
                            title="Show supporting content"
                            disabled={!answer.dataPoints.length}
                            onClick={onSupportingContentClicked}
                            icon={ClipboardBulletListLtrRegular} />
                    </div>
                </div>
            </div>

            <div>
                <div
                    className={styles.answerText}
                    dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}
                ></div>
            </div>

            {!!parsedAnswer.citations.length && (
                <div>
                            <span className={styles.citationLearnMore}>
                                Citations:
                            </span>
                    {parsedAnswer.citations.map((x, i) => {
                        return (
                            <a
                                key={i}
                                css={css`
                                    margin-right: 0.3em;
                                    //font-weight: 500;
                                    line-height: 24px;
                                    text-align: center;
                                    border-radius: calc(var(--border-radius) / 2);
                                    //padding: 0px 8px;
                                    padding: 0.3em;
                                    background: rgba(var(--main-color-rgb), 0.2);
                                    color: #123bb6;
                                    text-decoration: none;
                                    cursor: pointer;

                                    &:hover {
                                        text-decoration: underline;
                                    }
                                `}
                                title={x}
                                onClick={() => onCitationClicked(x)}
                            >
                                {`${++i}. ${x}`}
                            </a>
                        )
                    })}
                </div>
            )}
        </div>)
}
