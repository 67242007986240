import { ComponentType } from 'react'
import { DocumentContent } from './DocumentContent'
import { useApi } from '../api/useApi'

export const FacadeDocumentContent: ComponentType<
    Omit<Parameters<typeof DocumentContent>[0], 'getFile'> & { name: string }
> = (p) => {
    const api = useApi()

    let getFile

    let page = 1
    const [title, docIdStr] = p.name.split('|')

    const [_, pageStr] = title.split('/')
    const docId = parseInt(docIdStr)

    getFile = () => api.search.getDocumentContent(docId)
    page = parseInt(pageStr) + 1

    return <DocumentContent {...p} getFile={getFile} page={page} />
}
