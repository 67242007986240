import { css } from '@emotion/react'
import { useCollectionStore } from '../chat/SearchCollectionsStore'
import { Button } from '../ui/Button'
import { AppGeneric24Regular, Settings24Regular } from '@fluentui/react-icons'
import React, { ComponentType, useState } from 'react'
import { ChatbotSettingsComp } from '../chat/ChatbotSettingsComp'
import { useNavigate } from 'react-router-dom'
import { CollectionSelector } from '../admin/CollectionSelector'
import { useAppContext } from '../ctx/MyContext'
import { observer } from 'mobx-react'
import { useDrawerPanelState } from '../ui/DrawerPanel'


const Label: ComponentType<{ children: React.ReactNode }> = ({ children }) =>
    <label css={css`
        font-weight: bold;
        margin-right: 1em;
    `}>
        {children}
    </label>


export const DeveloperPanel = observer(() => {
    const col = useCollectionStore()

    const ctx = useAppContext()

    const isAdmin = ctx.auth.store.isAdmin

    const drawer = useDrawerPanelState()

    const nav = useNavigate()

    const { auth } = useAppContext()

    return <div css={css`
        display: flex;
        background: white;
        padding: 1em;
        align-items: center;
    `}>
        <Label>Knowledge base</Label>
        <CollectionSelector horizontal />
        <div css={css`
            margin-left: auto;
            display: flex;
        `}>
            {isAdmin && <Button
                icon={Settings24Regular}
                onClick={() => drawer.setShown(true)}
                css={css`
                    background: none;
                `}
            >
                Chatbot settings
            </Button>}
            {auth.store.isAdmin && <Button
                css={css`
                    background: none;
                `}
                icon={AppGeneric24Regular}
                onClick={() => nav('/admin')}
            >
                Admin
            </Button>}
        </div>
        <ChatbotSettingsComp state={drawer} />
    </div>
})
